import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
 
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const buy = () => {
	window.open("https://pancakeswap.finance/swap?outputCurrency=0x8338a17F65D99E286c240A9C08A5A4bAaa19Dc56");
}

class Token extends Component {

    render() {
        return (

            <div class="boxWrap2Token">

                <div class="storyH" >Tokenomics</div>
                <p class="tokenCon" >We want to show our commitment to the project, to ensure the peace of mind of our investors 90% of the funds raised during the presale will go directly to the liquidity pool which will be locked for a period of one year. The team tokens will be unvested gradually starting from the second month.</p>
                <div class="tokenM" >
                    <div class="storyConT">
                    <video class="tokenV2" src="https://cdn.discordapp.com/attachments/1050552088779825176/1105475998834765824/POSTEE.mp4" playsInline controls></video>

                        <p></p>
                        <p class="tokenConDiv">
                            <div class="tokenHSub">Total Supply 1,000,000,000 POST:</div>
                            <div class="tokenH2">The total supply is 1 billion POST tokens, no more tokens will ever be created.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Presale 353.500.000 POST During the Fairlaunch period, 353.500.000 POST:</div>
                            <div class="tokenH2">tokens were made available, allowing early supporters to take part in the project's success.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Contract AddressExchange Liquidity 201,495,000 POST A total of 201,495,000 POST:</div>
                            <div class="tokenH2">tokens were allocated for exchange liquidity, ensuring the token's availability and accessibility in the market.</div>

                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">New Liquidity Pools: 145.005.000 POST:</div>
                            <div class="tokenH2">Part of the proceeds will be used to add liquidity pools, 145,005,000 tokens has been set aside for this purpose. To be added to the new pairs.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Burnt 100,000,000 POST:</div>
                            <div class="tokenH2">A token burnout of 100,000,000 to further strengthen the value of investors' tokens.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Marketing 100,000,000 POST</div>
                            <div class="tokenH2">100 million POST tokens have been set aside for future marketing investments.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">CEX listing 100,000,000 POST</div>
                            <div class="tokenH2">A portion of the token supply, 100 million POST tokens, have been set aside for listing on centralized exchanges, increasing accessibility and exposure to a broader audience.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub" onClick={buy}><span class="buy">Buy POST Tokens</span></div>                           
                        </p>
                    </div>
                    <video class="tokenV" src="https://cdn.discordapp.com/attachments/1050552088779825176/1105475998834765824/POSTEE.mp4" playsInline controls></video>

                </div>

            </div>
        )
    }
}

export default Token;

