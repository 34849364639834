import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import transparent from '../assets/transparent.png';
import passport from '../assets/passport.png';
import placeholder from '../assets/placeholder.png';
import man1 from '../assets/man1.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Mission extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="storyH">OUR MISSION</div>

                <div class="about">
                    <div class="missionMain">
                        <img src={transparent} />
                        <p></p>
                        <div class="missionCon">
                            <p>Technology</p>
                            <div>Turn your memories into unique digital assets that can be shared, collected, and traded with others.</div>
                        </div>
                    </div>

                    <div class="missionMain">
                        <img src={placeholder} />
                        <p></p>
                        <div class="missionCon">
                            <p>Selling points</p>
                            <div>Digital kiosks will be located in popular tourist destinations to offer exclusive points of sale for our NFT postcards.</div>
                        </div>
                    </div>

                    <div class="missionMain">
                        <img src={passport} />
                        <p></p>
                        <div class="missionCon">
                            <p>Intangible souvenir</p>
                            <div>With Postee, you get the best of the digital world – an intangible souvenir that captures the essence of your travels as a digital asset that can be shared and treasured forever.</div>
                        </div>
                    </div>
                </div>
                <img class="man" src={man1}/>
            </div>
        )
    }
}

export default Mission;

