import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/Logo200.png';
import Ticker from './ticker';
import bsc from '../assets/bsc.png';
import mm from '../assets/mm.png';
import Coinsult from '../assets/Coinsult.png';
import AVE from '../assets/AVE.png';
import ps from '../assets/ps.png';
import scan from '../assets/scan.png';
import trust from '../assets/trust.png';
import dexview from '../assets/dex-view-logo.png';
import coinmarketcap from '../assets/coinmarketcap.png';
import pooCoin from '../assets/pooCoin.png';
import panCakeSwap from '../assets/panCakeSwap.png';
import coing from '../assets/coing.png';
import os from '../assets/os.png'
import telegram from '../assets/telegram.png';
import twitter from '../assets/twitter.png'

const opensea = () => {
	window.open("#");
}
 
const dis = () => {
	window.open("#");
}

const tweet = () => {
	window.open("#");
}

const tg = () => {
	window.open("#");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
                 <Ticker />

                <div class="footer">

                    <div class="partners">
                        <div class="partnerDiv">
                            <img src={mm} />
                            <img src={panCakeSwap} />
                            <img src={bsc} />
                            <img src={Coinsult} />
                            <img src={AVE} />
                            <img src={coinmarketcap} />
                        </div>

                        <div class="partnerDiv">
                            <img src={ps} />
                            <img src={scan} />
                            <img src={pooCoin} />
                            <img src={trust} />
                            <img src={dexview} />
                            <img src={coing} />
                        </div>
                    </div>

                    <div><img class="logoF" src={logo} /></div>
                    <div class="icons">
                        <div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>
                        <div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>
                         <div class="discord"><img class="osPic" onClick={tg} src={telegram} /></div>
                    </div>

                    <div class="iconsMob">
                        <div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>
                        <div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>
                         <div class="discord"><img class="osPic" onClick={tg} src={telegram} /></div>
                    </div>
                    <div class="copyright">Copyright © 2023 Postee. All Rights Reserved</div>
                </div>

            </div>
        )
    }
}

export default Footer;

