import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import Carousel from './carousel';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.jpg';
import img4 from '../assets/img4.jpg';
import img5 from '../assets/img5.jpg';
import img6 from '../assets/img6.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class NFTproject extends Component {

    render() {
        return (

            <div class="boxWrap2NFT">

                <div class="storyH" >How Postee works</div>

                <div class="about2">
                    <div class="imgAndConMain">
                        <div class="imgAndCon">
                            <img src={img3}/>
                            <div>The person realizes that this is a digital souvenir machine.</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={img2}/>
                            <div>All you need to do is scan the QR code displayed on the kiosk screen. A notification will pop up on the screen, confirming that you have purchased an NFT.</div>
                        </div>
                    </div>

                    <div class="imgAndConMain">
                        <div class="imgAndCon">
                            <img src={img3}/>
                            <div>The postee kiosk catches the tourist eye.</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={img4}/>
                            <div>All you need to do is scan the QR code displayed on the kiosk screen. A notification will pop up on the screen, confirming that you have purchased an NFT.</div>
                        </div>
                    </div>

                    <div class="imgAndConMain">
                        <div class="imgAndCon">
                            <img src={img5}/>
                            <div>The person realizes that this is a digital souvenir machine.</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={img6}/>
                            <div>The tourist’s smartphone will now display the NFT in their wallet.</div>
                        </div>
                    </div>
                    
                </div>

               <Carousel />

            </div>
        )
    }
}

export default NFTproject;

