import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class TokenBenefits extends Component {

    render() {
        return (

            <div class="boxWrap2TB">

                <div class="storyH" >Token Benefits</div>
                 <div class="tokenM2">
                    <div class="storyConT2">
                        <p></p>
                        <p class="tokenConDiv2" >
                            <div class="tokenHSub">Potential for long-term growth:</div>
                            <div class="tokenH2">By holding the POST token, investors can benefit from potential long-term growth as the project expands to more locations and gains more users.</div>
                        </p>

                        <p class="tokenConDiv2" >
                            <div class="tokenHSub">Access to exclusive NFTs:</div>
                            <div class="tokenH2">As the project grows, so too will the selection of unique and exclusive NFTs that can only be obtained through the POST devices. By holding the POST token, investors will have certain advantages.</div>
                        </p>

                        <p class="tokenConDiv2" >
                            <div class="tokenHSub">Limited token supply:</div>
                            <div class="tokenH2">With a limited token supply of only 1,000,000,000 the value of the POST token is a fixed amount of tokens, no more tokens will be minted in the future.</div>

                        </p>

                        <p class="tokenConDiv2" >
                            <div class="tokenHSub">Experienced team:</div>
                            <div class="tokenH2">The team behind the POST project is made up of computer science engineers. Experienced in the NFT world. This provides a level of confidence and trust in the project's ability to deliver on its promises.</div>
                        </p>

                        <p class="tokenConDiv2" >
                            <div class="tokenHSub">Achievements:</div>
                            <div class="tokenH2">Achievements will be enabled in the form of NFTs that will be awarded to those users who fulfill a series of conditions, such as visiting more than 10 countries, one country in each continent, etc.</div>
                        </p>
                                               
                    </div>

                </div>

            </div>
        )
    }
}

export default TokenBenefits;

