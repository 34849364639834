import React, { Component } from 'react';

class FAQ extends Component {

  render() {
    return (

      <div id="faq" class="faqbg">
        <div class="storyH">FAQ</div>

        <div class="accordin reveal">

          <details >
            <summary>Is your project SAFU?</summary>
            <div class="faq__content">
              <p>Yes, our project has been awarded a KYC badge from Pinksale and SAFU badge from Coinsult.</p>
            </div>
          </details>
          <details>
            <summary>How can I Contact the Team?</summary>
            <div class="faq__content">
              <p>The best way to contact our team is via Telegram. Join our group and ask a question, and we will be happy to answer it.</p>

            </div>
          </details>
          <details>
            <summary>How can I Buy Postee (POST) tokens?</summary>
            <div class="faq__content">
              <p>Postee (POST) tokens are going to be available in fairlaunch on the trusted Pinksale platform.</p>

            </div>
          </details>

          <details>
            <summary>Postee (POST) tokens are going to be available in fairlaunch on the trusted Pinksale platform.</summary>
            <div class="faq__content">
              <p>Yes, the source code of our token has been thoroughly checked by Coinsult.</p>

            </div>
          </details>
        </div>

      </div>


    )
  }
}

export default FAQ;

