import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import rmBg from '../assets/map1.png';
import building1 from '../assets/building1.png';
import building2 from '../assets/building2.png';
import building3 from '../assets/building3.png';
import building4 from '../assets/building4.png';
import q1 from '../assets/Q1.png';
import q2 from '../assets/Q2.png';
import q3 from '../assets/Q3.png';
import q4 from '../assets/Q4.png';
import q1Mob from '../assets/Q1Mob.png';
import q2Mob from '../assets/Q2Mob.png';
import q3Mob from '../assets/Q3Mob.png';
import q4Mob from '../assets/Q4Mob.png';
import baloon from '../assets/baloon.png'
import baloon2 from '../assets/baloon2.png'
import baloon3 from '../assets/baloon3.png'
import baloon4 from '../assets/baloon4.png'
import airB from '../assets/airBaloon.png'

class Roadmap extends Component {


    render() {
        return (

            <div class="boxWrap2">

                <div class="storyH">ROADMAP</div>

                <div class="rmBg">

                    <img class="baloon1" src={baloon} />
                    <img class="building1" src={building1} />
                    <img class="q1" src={q2} />

                    <img class="baloon2" src={baloon2} />
                    <img class="building2" src={building2} />
                    <img class="q2" src={q1} />

                    <img class="baloon3" src={baloon3} />
                    <img class="building3" src={building3} />
                    <img class="q3" src={q4} />

                    <img class="baloon4" src={baloon4} />
                    <img class="building4" src={building4} />
                    <img class="q4" src={q3} />

                    <img class="airB" src={airB} />
                   
                    <img class="rmPic" src={rmBg} />

                </div>

                <div class='rmMobile'>
                    <div class='rmMobileDiv'>
                        <img class="chatbxMob" src={q1Mob} />
                        <img class="stadiumMob" src={building1} />
                    </div>

                    <div class='rmMobileDiv'>
                        <img class="chatbxMob" src={q2Mob} />
                        <img class="stadiumMob" src={building2} />
                    </div>

                    <div class='rmMobileDiv'>
                        <img class="chatbxMob" src={q3Mob} />
                        <img class="stadiumMob" src={building4} />                
                    </div>

                    <div class='rmMobileDiv'>
                        <img class="chatbxMob" src={q4Mob} />
                        <img class="stadiumMob" src={building3} />
                    </div>


                </div>
            </div>

        );

    }
}

export default Roadmap;